import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import HeaderItem from './HeaderItem';
import HeaderSkeleton from './HeaderSkeleton';
import useCurrentPresentation from '../features/presentation/useCurrentPresentation';
import usePresentationFlow from '../features/presentationFlow/usePresentationFlow';
import NotificationDialog from '../features/notification/NotificationDialog';

interface HeaderProps {
    width: any;
    gutter: any;
    toggleDrawer: () => void;
}

/**
 * Generer le path pour les outils.
 * Si un déroulé est en cours le lien pointe directement sur
 * la rubrique d'outils qui lui correspond.
 */
function getToolCategoriesLink(
    presentationFlow: PresentationFlowResponse | undefined
) {
    const basePath = '/rubriques';

    const toolCategoryId = getIdFromIri(
        entityToId(presentationFlow?.toolCategory)
    );

    if (toolCategoryId) {
        return `${basePath}/${toolCategoryId}`;
    }

    return basePath;
}

function Header({ width, gutter, toggleDrawer }: HeaderProps) {
    const [currentPresentation] = useCurrentPresentation();
    const presentationFlow = usePresentationFlow(
        currentPresentation?.presentationFlowId
    );

    const toolsLink = useMemo(
        () => getToolCategoriesLink(presentationFlow),
        [presentationFlow]
    );

    return (
        <HeaderSkeleton width={width} gutter={gutter}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <NotificationDialog />
                <HeaderItem to="/mes-rendez-vous">Mes rendez-vous</HeaderItem>
                <HeaderItem to="/presentation" disabled={!currentPresentation}>
                    Présentation
                </HeaderItem>
                <HeaderItem to={toolsLink}>Outils</HeaderItem>
                <IconButton
                    onClick={toggleDrawer}
                    data-testid="MenuSideBarButton"
                    disabled={!currentPresentation}
                    size="small"
                >
                    <ListIcon fontSize="large" />
                </IconButton>
            </Box>
        </HeaderSkeleton>
    );
}

export default Header;
