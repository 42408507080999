import React, { ElementType, useCallback } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import FlexScrollable from '@acdc/shared/src/ui/FlexScrollable';
import useSelectedPackageId from './useSelectedPackageId';
import BigRoundedButton from '../../ui/buttons/BigRoundedButton';
import PackageLogo from './PackageLogo';
import { PackageSummaryData } from './usePackagesSummaries';

function ItemTypography({
    children,
    component,
}: {
    children: React.ReactNode;
    component?: ElementType<any> | undefined;
}) {
    return (
        <Box
            component={component || 'div'}
            textAlign="center"
            fontSize="0.9rem"
        >
            {children}
        </Box>
    );
}

interface PackageSummaryProps {
    summary: PackageSummaryData;
}

/**
 * Résumé d'un Package.
 */
function PackageSummary({ summary }: PackageSummaryProps) {
    const [, setSelectedPackage] = useSelectedPackageId();
    const selectPackage = useCallback(() => {
        setSelectedPackage(summary.overridedPackage.id);
        (window as any)?.fullpage_api?.moveSectionDown();
    }, [setSelectedPackage, summary.overridedPackage.id]);

    return (
        <Paper
            sx={{
                height: '100%',
                width: '100%',
                px: 1,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <PackageLogo
                sx={{ width: '80px' }}
                src={`${summary.overridedPackage.packageImageUrl}`}
            />
            <Box
                component="h4"
                my={2}
                color={summary.overridedPackage.color}
                fontWeight="bolder"
                fontFamily="Lexend"
                textTransform="uppercase"
            >
                {summary.overridedPackage.label}
            </Box>
            <FlexScrollable sx={{ width: '100%' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="nowrap"
                    alignItems="center"
                >
                    {summary.previousOverridedPackage && (
                        <>
                            <ItemTypography>
                                Prestations du «{' '}
                                <i>
                                    {`Pack ${summary.previousOverridedPackage.label}`}
                                </i>
                                {' '}» incluses
                            </ItemTypography>
                            <AddCircleRoundedIcon
                                sx={{
                                    color: summary.overridedPackage.color,
                                }}
                            />
                        </>
                    )}
                    <Box
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={0}
                        m={0}
                        sx={{
                            listStyleType: 'none',
                        }}
                    >
                        {!summary.previousOverridedPackage && (
                            <ItemTypography component="li">
                                • Prestations initiales incluses
                            </ItemTypography>
                        )}
                        {summary.includedAdditionalServices.map(
                            (service: ServiceResponse) => (
                                <ItemTypography key={service.id} component="li">
                                    • {service.label}
                                </ItemTypography>
                            )
                        )}
                        {!summary.includedAdditionalServices.length && (
                            <ItemTypography component="li">
                                • Prestations annexes payantes
                            </ItemTypography>
                        )}
                    </Box>
                </Box>
            </FlexScrollable>
            <Stack direction="column" spacing={2} justifyContent="center">
                <Box
                    sx={{
                        borderColor: 'primary.dark',
                        borderWidth: '2px 0px',
                        borderStyle: 'solid',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        component="b"
                        variant="h2"
                        color="primary.dark"
                        margin={0}
                    >
                        {summary.packageAgency.rate}%
                    </Typography>{' '}
                    <Typography
                        component="span"
                        color="primary.dark"
                        sx={{ verticalAlign: 'bottom' }}
                    >
                        TTC
                    </Typography>
                </Box>

                <BigRoundedButton
                    data-testid="SelectPackageButton"
                    color="primary"
                    onClick={selectPackage}
                >
                    Sélectionner
                </BigRoundedButton>
            </Stack>
        </Paper>
    );
}

export default PackageSummary;
