import { Box, SxProps } from '@mui/material';
import React from 'react';

const absoluteSx = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto',
};

export interface FlexScrollableProps {
    sx?: SxProps;
    children?: React.ReactNode;
}

/**
 * Wrapper utilisable dans un container flex.
 * Il a flex:1 mais si son contenu est plus grand que l'espace flexible, le scroll
 * sera disponible.
 */
function FlexScrollable({ sx, children }: FlexScrollableProps) {
    return (
        <Box
            data-testid="FlexScrollable"
            sx={{ flex: 1, position: 'relative', ...sx }}
            className="FullPageScrollable"
        >
            <Box sx={absoluteSx}>{children}</Box>
        </Box>
    );
}

export default FlexScrollable;
