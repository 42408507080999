import {
    Box,
    Button,
    LinearProgress,
    Step,
    StepLabel,
    Stepper,
    Typography,
    Theme,
} from '@mui/material';
import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import type { LinkItem } from './Menu';
import CustomStepIcon from './CircleStepIcon';
import useEditMode from '../template/useEditMode';
import StatusPage from './StatusPage';
import EditContentLink from './EditContentLink';
import Presentation from '../presentation/Presentation';
import getPresentationFlowColor from '../presentationFlow/getPresentationFlowColor';

interface CategoryProps {
    title: string;
    nav: LinkItem[];
    currentNav: number;
    number: number;
    currentState?: string;
    currentPresentation: Presentation | undefined;
    toggleDrawer?: () => void;
}
const iconSx = {
    margin: 1,
};

function Category({
    title,
    nav,
    currentNav,
    number,
    currentState,
    currentPresentation,
    toggleDrawer,
}: CategoryProps) {
    const [editMode] = useEditMode();
    const numberCategory: string = number.toString().padStart(2, '0');

    // Changement des couleurs en fonction du type de présentation séléctionné
    const presentationFlowType = getIdFromIri(
        currentPresentation?.presentationFlowId
    );
    const themePalette = getPresentationFlowColor(
        currentPresentation?.presentationFlowId
    );
    const presentationColorMain = (theme: Theme) =>
        theme.palette[themePalette].main;
    const presentationColorLight = (theme: Theme) =>
        theme.palette[themePalette].light;

    const confButton = (theme: Theme) => ({
        color: presentationColorMain,
        ...(currentState === 'fullscreen' &&
            nav.length > 4 && {
                padding: '1px 8px',
            }),
        ...(currentState === 'fullscreen' &&
            nav.length > 5 && {
                padding: '0px 8px',
            }),
        [theme.breakpoints.down('lg')]: {
            fontSize: '0.6rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '0.7rem',
        },
    });

    return (
        <Box
            data-testid="categoryMenu"
            sx={{
                marginBottom: '0.5rem',
                overflow: 'hidden',
                ...(currentState === 'fullscreen' && {
                    flexBasis: '31%',
                    marginTop: 0,
                    minheight: 'calc((100vh - 88px)/3)', // 88 = header (64) + padding Bottom of 3 Category (8 each)
                }),
                ...(currentState !== 'fullscreen' && {
                    flexBasis: '100%',
                }),
            }}
            m={1}
        >
            <LinearProgress
                color={themePalette}
                variant="determinate"
                value={9}
            />
            <Box
                data-testid={presentationFlowType}
                sx={{
                    display: 'flex',
                    marginTop: '1rem',
                }}
            >
                <Typography
                    variant="h4"
                    component="p"
                    sx={{
                        fontWeight: 'bold',
                        color: presentationColorMain,
                    }}
                >
                    {numberCategory}
                </Typography>
                <Box
                    sx={{
                        width: 'calc(100% - 1rem)',
                        marginLeft: '1rem',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={(theme) => ({
                            whiteSpace: 'normal',
                            color: presentationColorLight,
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '0.9rem',
                            },
                            [theme.breakpoints.up('lg')]: {
                                fontSize: '1.2rem',
                            },
                        })}
                    >
                        {title}
                    </Typography>
                    <Stepper
                        activeStep={currentNav}
                        orientation="vertical"
                        sx={{
                            marginTop: '0.5rem',
                            '& .Mui-completed': {
                                '& .MuiButton-root': {
                                    color: presentationColorLight,
                                },
                                '& .MuiStepLabel-iconContainer .MuiBox-root': {
                                    background: presentationColorLight,
                                    borderColor: presentationColorLight,
                                },
                            },
                        }}
                    >
                        {nav.map((item: LinkItem) => (
                            <Step key={item.link}>
                                <StepLabel
                                    sx={{ width: '100%' }}
                                    StepIconComponent={CustomStepIcon}
                                >
                                    <Box
                                        sx={
                                            editMode
                                                ? {
                                                      display: 'flex',
                                                      width: '100%',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                          'space-between',
                                                  }
                                                : {}
                                        }
                                    >
                                        <Button
                                            component={Link}
                                            to={`/presentation#${item.link}`}
                                            className="active"
                                            sx={(theme) => confButton(theme)}
                                        >
                                            {item.name}
                                        </Button>
                                        {editMode && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {!item.disabled ? (
                                                    <VisibilityIcon
                                                        sx={iconSx}
                                                    />
                                                ) : (
                                                    <VisibilityOffIcon
                                                        sx={iconSx}
                                                    />
                                                )}
                                                <StatusPage
                                                    content={item.content}
                                                    iconSx={iconSx}
                                                />
                                                <EditContentLink
                                                    content={item.content}
                                                    to={`/presentation#${item.link}`}
                                                    onClick={toggleDrawer}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
        </Box>
    );
}

export default Category;
