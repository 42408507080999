import { Box } from '@mui/material';
import React from 'react';
import PackageLogo from '../PackageLogo';
import usePackage from '../usePackage';
import useOverridedPackage from '../useOverridedPackage';

export interface PackageIdentityProps {
    packageId: string;
    width: string | number;
}

/**
 * Logo et label représentant un Package.
 */
function PackageIdentity({ packageId, width }: PackageIdentityProps) {
    const pack = usePackage(packageId);
    const overridedPackage = useOverridedPackage(pack);

    if (!overridedPackage) {
        return null;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={width}
        >
            <PackageLogo
                sx={{ width: '40px', padding: 0.5, mb: 1 }}
                src={`${overridedPackage.packageImageUrl}`}
            />
            <Box
                color="primary.dark"
                textTransform="uppercase"
                fontFamily="Lexend"
                fontSize="0.8rem"
            >
                Pack
            </Box>
            <Box
                color={overridedPackage.color}
                fontWeight="bolder"
                fontFamily="Lexend"
                textTransform="uppercase"
                textAlign="center"
                fontSize="0.9rem"
            >
                {overridedPackage.label}
            </Box>
        </Box>
    );
}

export default PackageIdentity;
