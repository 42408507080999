/**
 * @see https://stackoverflow.com/a/48768775
 */
function enumValues(someEnum: any): string[] {
    return Object.keys(someEnum).reduce((curr: string[], unit: string) => {
        if (Number.isNaN(Number(unit))) {
            curr.push(someEnum[unit]);
        }

        return curr;
    }, []);
}

export default enumValues;
