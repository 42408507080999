import React from 'react';
import { Grid } from '@mui/material';
import PackageSummary from './PackageSummary';
import useSelectedAgency from '../agency/useSelectedAgency';
import PackageSummariesListSkeleton from './PackageSummariesListSkeleton';
import useAgency from '../agency/useAgency';
import usePackagesSummaries from './usePackagesSummaries';

/**
 * Liste des résumés des Packages.
 */
function PackageSummariesList() {
    const agency = useAgency(useSelectedAgency()[0]);
    const summaries = usePackagesSummaries(agency);

    if (!summaries?.length) {
        return <PackageSummariesListSkeleton />;
    }

    return (
        <Grid
            container
            data-testid="PackageTeasersList"
            spacing={2}
            height="100%"
        >
            {summaries?.map((summary) => (
                <Grid
                    item
                    key={`${summary.overridedPackage.id}`}
                    sm
                    height="100%"
                >
                    <PackageSummary summary={summary} />
                </Grid>
            ))}
        </Grid>
    );
}

export default PackageSummariesList;
