import React from 'react';
import { Box } from '@mui/material';

export const normalContentHeadingSx = {
    fontFamily: 'Lexend',
    fontSize: '140%',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    color: 'primary.contrastText',
    backgroundColor: 'primary.dark',
    paddingX: '1.5em',
    paddingY: '0.265em',
    display: 'inline-block',
    borderRadius: 0.5,
};

export const bigContentHeadingSx = {
    ...normalContentHeadingSx,
    fontSize: 27,
    paddingY: 1,
};

export interface ContentHeadingProps {
    children: React.ReactNode;
    size?: 'normal' | 'big';
    /**
     * override du margin bottom
     */
    mb?: any;
}

function ContentHeading({ children, size, mb }: ContentHeadingProps) {
    return (
        <Box
            component="h3"
            sx={{
                ...(size === 'big'
                    ? bigContentHeadingSx
                    : normalContentHeadingSx),
                ...(mb !== undefined && { mb }),
            }}
            my={2}
        >
            {children}
        </Box>
    );
}

export default ContentHeading;
