import React from 'react';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import AgenciesProvider from './agency/AgenciesProvider';
import PackagesProvider from './package/PackagesProvider';
import PresentationFlowsProvider from './presentationFlow/PresentationFlowsProvider';
import PropertiesProvider from './property/PropertiesProvider';
import ServicesProvider from './service/ServicesProvider';
import ToolsProvider from './tools/ToolsProvider';
import SetCacheAgeAfterInitialization from './SetCacheAgeAfterInitialization';
import AutoRefreshApiData from './AutoRefreshApiData';
import ToolCategoriesProvider from './toolsCategory/ToolCategoriesProvider';
import useSelectedAgency from './agency/useSelectedAgency';
import AuthenticatedUserProvider from './user/AuthenticatedUserProvider';

export interface ApiDataProviderProps {
    children: React.ReactNode;
    export?: boolean | undefined;
}

/**
 * Charge toutes les données requises sur le front et les met à disposition dans des contextes
 * utilisables avec usePresentationFlows(), usePackages()...
 * Il faut utiliser ce Provider à un endroit où l'utilisateur est authentifié.
 */
function ApiDataProvider({
    children,
    export: shouldPartialLoadProvider = false,
}: ApiDataProviderProps) {
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();

    // Si l'utilisateur est consultant pour éviter de charger toutes les configurations de toutes les agences
    // on ne charge que les configurations de l'agence selectionnée.
    const onlyAgency = isConsultant ? selectedAgency : undefined;

    // Lors de la connexion, on a pas directement une agence selectionnée.
    // Il ne faut pas que les requêtes chargent toutes les configs des agences
    // des consultants le temps que la première agence soit selectionnée.
    // Donc on indique a ces requêtes de se skip en attendant.
    const skipAgencyRequests = Boolean(isConsultant && !selectedAgency);

    return (
        <AuthenticatedUserProvider>
            <PresentationFlowsProvider>
                <ToolsProvider>
                    {shouldPartialLoadProvider ? (
                        <AgenciesProvider
                            agency={onlyAgency}
                            skipAgencyRequests={skipAgencyRequests}
                            enabledPreloadImage={false}
                        >
                            <PropertiesProvider
                                agency={onlyAgency}
                                skipAgencyRequests={skipAgencyRequests}
                            >
                                <SetCacheAgeAfterInitialization />
                                <AutoRefreshApiData />
                                {children}
                            </PropertiesProvider>
                        </AgenciesProvider>
                    ) : (
                        <ToolCategoriesProvider>
                            <PackagesProvider>
                                <ServicesProvider>
                                    <AgenciesProvider
                                        agency={onlyAgency}
                                        skipAgencyRequests={skipAgencyRequests}
                                        enabledPreloadImage
                                    >
                                        <PropertiesProvider
                                            agency={onlyAgency}
                                            skipAgencyRequests={
                                                skipAgencyRequests
                                            }
                                        >
                                            <SetCacheAgeAfterInitialization />
                                            <AutoRefreshApiData />
                                            {children}
                                        </PropertiesProvider>
                                    </AgenciesProvider>
                                </ServicesProvider>
                            </PackagesProvider>
                        </ToolCategoriesProvider>
                    )}
                </ToolsProvider>
            </PresentationFlowsProvider>
        </AuthenticatedUserProvider>
    );
}

export default ApiDataProvider;
