enum ServiceUnit {
    EURO = '€',
    EURO_PER_YEAR = '€/an',
    EURO_PER_CALL = '€/appel',
    EURO_PER_BILL = '€/facture',
    EURO_PER_HOURS = '€/heure',
    EURO_PER_MONTH = '€/mois',
    EURO_PER_DATE = '€/rdv',
    EURO_PER_VISIT = '€/visite',
    PERCENT = '%',
    DISABLED = 'désactiver',
}

export default ServiceUnit;
