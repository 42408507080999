import { Box } from '@mui/material';
import React from 'react';

function Logo() {
    return (
        <Box data-testid="buttonEasterEgg">
            <img
                src="/images/logo-laforet.png"
                alt="Logo LaForêt"
                data-testid="logoLaForet"
                style={{
                    width: 'auto',
                    height: '40px',
                    marginRight: '10px',
                }}
            />
        </Box>
    );
}

export default Logo;
