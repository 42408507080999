import FlexImage from '@acdc/shared/src/ui/FlexImage';
import { Paper, SxProps } from '@mui/material';
import React from 'react';

export interface PackageLogoProps {
    src: string;
    sx?: SxProps | undefined;
}

function PackageLogo({ src, sx }: PackageLogoProps) {
    return (
        <Paper
            elevation={4}
            sx={{
                aspectRatio: '1 / 1',
                borderRadius: '50%',
                display: 'flex',
                padding: 2,
                backgroundColor: 'grey.100',
                ...sx,
            }}
        >
            <FlexImage src={src} alt="" />
        </Paper>
    );
}

export default PackageLogo;
