import React, { useCallback, useMemo } from 'react';
import { InputAdornment, Stack, Box, Theme, Grid } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import { initNumber } from '@acdc/shared/src/utils/form-helpers';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import QuotationInput from './QuotationInput';
import QuotationStack from '../QuotationStack';
import QuotationValuesModel from '../QuotationValues.model';
import PreviewTotalRentalFees from './PreviewTotalRentalFees';
import CONFIGS from '../../../configs';
import AutoPersistQuotation from './AutoPersistQuotation';
import quotationFormValueToModel from './quotationFormValueToModel';
import FakeLoadingScreenButton from '../../../ui/buttons/FakeLoadingScreenButton';
import QuotationActions from '../QuotationActions';
import AutoUpdatePackFee from './AutoUpdatePackFee';
import QuotationFormValue from './QuotationFormValue';
import ContentHeading from '../../content/ContentHeading';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import usePackage from '../../package/usePackage';
import useOverridedPackage from '../../package/useOverridedPackage';

const quotationFormSchema = Yup.object().shape({
    // au centre
    monthlyRent: Yup.number().label('Le loyer mensuel').optional(),
    monthlyProvisionsOnCharges: Yup.number()
        .label('Les provisions sur charges')
        .optional(),

    // à gauche
    surface: Yup.number().label('La surface').optional(),
    visitFee: Yup.number().label('Les honoraires de visite').optional(),
    initialInventoryFee: Yup.number()
        .label("Les honoraires d'état des lieux")
        .optional(),
    biddingAndNegotiation: Yup.number()
        .label('Entremise et négociation')
        .optional(),
    previewTotalRentalFees: Yup.number()
        .label('Les honoraires prévisionnels')
        .optional(),

    // à droite
    annualWasteTaxe: Yup.number()
        .label("Les taxes d'ordures ménagères")
        .optional(),
    packFee: Yup.number().label('Les honoraires du pack').optional(),
    unpaidRentGuarantee: Yup.number()
        .label("L'assurance loyers impayés")
        .optional(),
    annualNonOccupantOwnerInsurance: Yup.number()
        .label("L'assurance P.N.O")
        .optional(),
});

const initValue = (
    value: DeepPartial<QuotationValuesModel>
): QuotationFormValue => ({
    monthlyRent: initNumber(value.monthlyRent),
    monthlyProvisionsOnCharges: initNumber(value.monthlyProvisionsOnCharges),
    surface: initNumber(value.surface),
    visitFee: initNumber(value.visitFee),
    initialInventoryFee: initNumber(value.initialInventoryFee),
    biddingAndNegotiation: initNumber(value.biddingAndNegotiation),
    previewTotalRentalFees: '',
    annualWasteTaxe: initNumber(value.annualWasteTaxe),
    packFee: initNumber(value.packFee),
    unpaidRentGuarantee: initNumber(value.unpaidRentGuarantee),
    annualNonOccupantOwnerInsurance: initNumber(
        value.annualNonOccupantOwnerInsurance
    ),
});

interface QuotationFormProps {
    onSuccess?: (values: QuotationValuesModel) => void;
}

function QuotationForm({ onSuccess }: QuotationFormProps) {
    const [value, saveValue] = usePersistentState<
        DeepPartial<QuotationValuesModel>
    >(CONFIGS.currentQuotationStorageKey);
    const selectedOverridedPackage = useOverridedPackage(
        usePackage(useSelectedPackageId()[0])
    );

    const initialValues: QuotationFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const handleSubmit = useCallback(
        (
            formValues: QuotationFormValue,
            { setSubmitting }: FormikHelpers<QuotationFormValue>
        ) => {
            const modelValue = quotationFormValueToModel(formValues);
            setSubmitting(false);
            saveValue(modelValue);
            onSuccess && onSuccess(modelValue);
        },
        [onSuccess, saveValue]
    );

    return (
        <Box>
            <ContentHeading size="big">
                <Box component="span" sx={visuallyHidden}>
                    Compléter
                </Box>{' '}
                Votre devis
            </ContentHeading>
            <Formik
                validationSchema={quotationFormSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ submitForm }: FormikProps<QuotationFormValue>) => (
                    <Form>
                        {/* Sauvegarde auto dans le localstorage pour reprendre plus tard */}

                        <AutoPersistQuotation />
                        <AutoUpdatePackFee />
                        <Stack
                            direction="column"
                            spacing={4}
                            sx={{ alignItems: 'center' }}
                        >
                            <Stack direction="column" spacing={2}>
                                <QuotationInput
                                    inputId="monthlyRent"
                                    name="monthlyRent"
                                    label="Loyer mensuel H.C"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                €
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: '0',
                                            step: '0.01',
                                            'data-testid': 'monthlyRentInput',
                                        },
                                    }}
                                />
                                <QuotationInput
                                    inputId="monthlyProvisionsOnCharges"
                                    name="monthlyProvisionsOnCharges"
                                    label="Provisions sur charges"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                €
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: '0',
                                            step: '0.01',
                                            'data-testid':
                                                'monthlyProvisionsOnChargesInput',
                                        },
                                    }}
                                />
                            </Stack>
                            <Box width="100%">
                                {/* cette div est nécessaire sinon le Stack parent remplace le margin left de Grid */}
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <QuotationStack
                                            color={(theme: Theme) =>
                                                theme.palette.rental.main
                                            }
                                            title="location"
                                        >
                                            <>
                                                <QuotationInput
                                                    inputId="surface"
                                                    name="surface"
                                                    label="Surface"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                m²
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '1',
                                                            'data-testid':
                                                                'surfaceInput',
                                                        },
                                                    }}
                                                />
                                                <QuotationInput
                                                    inputId="visitFee"
                                                    name="visitFee"
                                                    label="Honoraires dossier et visites"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                € / m²
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '0.01',
                                                            'data-testid':
                                                                'visitFeeInput',
                                                        },
                                                    }}
                                                />
                                                <QuotationInput
                                                    inputId="initialInventoryFee"
                                                    name="initialInventoryFee"
                                                    label="Honoraires état des lieux"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                € / m²
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '0.01',
                                                            'data-testid':
                                                                'initialInventoryFeeInput',
                                                        },
                                                    }}
                                                />
                                                <QuotationInput
                                                    inputId="biddingAndNegotiation"
                                                    name="biddingAndNegotiation"
                                                    label="Frais d'entremise et négociation"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                €
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '1',
                                                            'data-testid':
                                                                'biddingAndNegotiationInput',
                                                        },
                                                    }}
                                                />
                                                <PreviewTotalRentalFees
                                                    id="previewTotalRentalFees"
                                                    name="previewTotalRentalFees"
                                                />
                                            </>
                                        </QuotationStack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <QuotationStack
                                            color={(theme: Theme) =>
                                                theme.palette.warning.main
                                            }
                                            title="gestion"
                                        >
                                            <>
                                                {/* <QuotationInput
                                                    inputId="annualWasteTaxe"
                                                    name="annualWasteTaxe"
                                                    label="Taxe d'ordures ménagères"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                € / an
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '0.01',
                                                            'data-testid':
                                                                'annualWasteTaxeInput',
                                                        },
                                                    }}
                                                /> */}
                                                <QuotationInput
                                                    inputId="packFee"
                                                    name="packFee"
                                                    label={`Honoraires pack ${
                                                        selectedOverridedPackage?.label
                                                            ? `${selectedOverridedPackage?.label} `
                                                            : ''
                                                    }T.T.C`}
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '1',
                                                            'data-testid':
                                                                'packFeeInput',
                                                        },
                                                    }}
                                                />
                                                <QuotationInput
                                                    inputId="unpaidRentGuarantee"
                                                    name="unpaidRentGuarantee"
                                                    label="Assurance loyers impayés T.T.C"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '0.01',
                                                            'data-testid':
                                                                'unpaidRentGuaranteeInput',
                                                        },
                                                    }}
                                                />
                                                <QuotationInput
                                                    inputId="annualNonOccupantOwnerInsurance"
                                                    name="annualNonOccupantOwnerInsurance"
                                                    label="Assurance PNO T.T.C"
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                €
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: '0',
                                                            step: '1',
                                                            'data-testid':
                                                                'annualNonOccupantOwnerInsuranceInput',
                                                        },
                                                    }}
                                                />
                                            </>
                                        </QuotationStack>
                                    </Grid>
                                </Grid>
                            </Box>
                            <QuotationActions sx={{ width: '100%' }}>
                                <FakeLoadingScreenButton
                                    buttonProps={{
                                        type: 'button',
                                        'data-testid':
                                            'submitQuotationFormButton',
                                        color: 'primary',
                                    }}
                                    loadingDuration={1000}
                                    onClick={() => submitForm()}
                                >
                                    Voir le résultat
                                </FakeLoadingScreenButton>
                            </QuotationActions>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

export default QuotationForm;
