import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import useSelectedAgency from '../../agency/useSelectedAgency';
import usePackageAgenciesOf from '../../packageAgency/usePackageAgenciesOf';
import ServicePrice from './ServicePrice';

export interface ServicesTableRowProps {
    service: ServiceResponse;
    valueCellWidth: string | number;
}

/**
 * Les lignes de service d'un Package.
 */
function ServicesTableRow({ service, valueCellWidth }: ServicesTableRowProps) {
    const packageAgencies = usePackageAgenciesOf(useSelectedAgency()[0], true);

    return (
        <TableRow
            key={`${service.id}`}
            sx={{
                '&:nth-of-type(even)': {
                    backgroundColor: 'grey.50',
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: 'common.white',
                },
                '& > td': {
                    border: 'none',
                },
            }}
        >
            <TableCell>{service.longLabel}</TableCell>
            {packageAgencies?.map((packageAgency) => (
                <TableCell
                    key={`${packageAgency.id}`}
                    width={valueCellWidth}
                    align="center"
                >
                    <ServicePrice
                        service={service}
                        pack={packageAgency.package}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}

export default ServicesTableRow;
