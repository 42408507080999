import React from 'react';
import { Box } from '@mui/system';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import Logo from './Logo';
import SizeInterface from './SizeInterface';
import UserMenu from './UserMenu';
import CONFIGS from '../configs';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import useAgency from '../features/agency/useAgency';

interface HeaderSkeletonProps {
    children: React.ReactNode;
    width: SizeInterface;
    gutter: SizeInterface;
}

function HeaderSkeleton({ children, width, gutter }: HeaderSkeletonProps) {
    const [user] = usePersistentState(CONFIGS.userDataStorageKeys.currentUser);
    const selectedAgency = useAgency(useSelectedAgency()[0]);

    return (
        <AppBar
            id="header"
            sx={{
                position: 'fixed',
                backgroundColor: 'background.default',
                boxShadow: 'none',
                borderBottom: 'thin solid',
                borderColor: 'grey.500',
                width: { ...width },
                left: { ...gutter },
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: { xs: 0, lg: 3 },
                    pt: 4,
                    pb: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                    }}
                >
                    <Logo />

                    <UserMenu>
                        <Typography
                            component="span"
                            sx={{
                                fontSize: { sm: '0.7rem', md: '0.8rem' },
                            }}
                        >
                            Présenté par&nbsp;
                        </Typography>
                        {user && (
                            <Typography
                                component="span"
                                sx={{
                                    textDecoration: 'underline',
                                    fontSize: { sm: '0.7rem', md: '0.8rem' },
                                }}
                            >
                                {user.firstname} {user.lastname}
                            </Typography>
                        )}
                        {selectedAgency && (
                            <Typography
                                component="span"
                                sx={{
                                    textDecoration: 'underline',
                                    fontSize: { sm: '0.7rem', md: '0.8rem' },
                                }}
                            >
                                &nbsp;{`(${selectedAgency.name})`}
                            </Typography>
                        )}
                    </UserMenu>
                </Box>
                {children}
            </Toolbar>
        </AppBar>
    );
}

export default HeaderSkeleton;
