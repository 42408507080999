import { filterNumber } from '@acdc/shared/src/utils/form-helpers';
import QuotationFormValue from './QuotationFormValue';
import type QuotationValuesModel from '../QuotationValues.model';

function quotationFormValueToModel(
    formValues: QuotationFormValue
): QuotationValuesModel {
    const modelValue: any = {};
    const keys = Object.keys(formValues);
    keys.forEach((key) => {
        const filtered = filterNumber((formValues as any)[key]);
        modelValue[key] = filtered === null ? undefined : filtered;
    });

    return modelValue as QuotationValuesModel;
}

export default quotationFormValueToModel;
