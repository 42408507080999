import React, { useMemo } from 'react';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { Box } from '@mui/material';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import useAgency from '../../agency/useAgency';
import useSelectedAgency from '../../agency/useSelectedAgency';

function getStatus(
    servicePackageOverride: ServicePackageOverrideResponse | undefined
): 'included' | 'disabled' | 'charged' {
    if (!servicePackageOverride) {
        return 'included';
    }
    if (servicePackageOverride.unit === ServiceUnit.DISABLED) {
        return 'disabled';
    }
    if (servicePackageOverride.price || servicePackageOverride.price === 0) {
        return 'charged';
    }
    return 'included';
}

export interface ServicePriceProps {
    service: ServiceResponse;
    pack: PackageResponse | undefined;
}

/**
 * Retourne soit le montant du Service (avec l'unité) soit un icon qui indique que le service est inclu dans le pack.
 */
function ServicePrice({ service, pack }: ServicePriceProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const servicePackageOverride = useMemo(
        () =>
            agency?.servicePackageOverrides?.collection?.find(
                (sp) =>
                    matchOnId(sp.service, service) &&
                    matchOnId(sp.package, pack)
            ),
        [service, agency, pack]
    );
    const status = useMemo(
        () => getStatus(servicePackageOverride),
        [servicePackageOverride]
    );

    switch (status) {
        case 'included':
            return <CheckCircleRoundedIcon color="success" />;
        case 'charged':
            return (
                <>
                    <strong>{servicePackageOverride?.price}</strong>
                    <Box component="span" fontSize="0.8rem">
                        {servicePackageOverride?.unit}
                    </Box>
                </>
            );
        case 'disabled':
            return <CancelIcon color="error" />;
        default:
            // Normalement impossible car toutes les valeurs possibles ont un case.
            return <></>;
    }
}

export default ServicePrice;
