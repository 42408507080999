import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import User from '@acdc/shared/src/features/user/User.model';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import BigRoundedButton from '../../../ui/buttons/BigRoundedButton';
import QuotationActions from '../QuotationActions';
import QuotationValuesModel from '../QuotationValues.model';
import useQuotationManagementTotals from './useQuotationManagementTotals';
import useQuotationRentTotals from './useQuotationRentTotals';
import QuotationRentResult from './QuotationRentResult';
import QuotationManagementResult from './QuotationManagementResult';
import ContentHeading from '../../content/ContentHeading';
import SendQuotationFormButton from '../SendQuotationFormButton';
import QuotationRentTotals from '../QuotationRentTotals.model';
import QuotationManagementTotals from '../QuotationManagementTotals.model';
import CONFIGS from '../../../configs';
import useProperty from '../../property/useProperty';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import useCurrentPresentation from '../../presentation/useCurrentPresentation';

function makeQuotation(
    value: Partial<QuotationValuesModel>,
    rentTotals: Partial<QuotationRentTotals>,
    managementTotals: Partial<QuotationManagementTotals>,
    presenter: string | Partial<User>,
    property: PropertyResponse | undefined,
    agency: AgencyResponse | string | undefined,
    pack: PackageResponse | string | null | undefined
): DeepPartial<Quotation> {
    return {
        presenter: entityToId(presenter) || undefined,
        agency: entityToId(agency) || undefined,
        pack: entityToId(pack) || undefined,
        clientEmailAddress: property?.clientEmail,
        clientLastname: property?.clientLastname,
        clientFirstname: property?.clientFirstname || undefined,
        clientPhone: property?.clientPhone,
        propertyId: property?.id
            ? parseInt(getIdFromIri(entityToId(property))!, 10)
            : undefined,
        propertyAddress: property?.address,
        propertyStreet: property?.street,
        propertyPostCode: property?.postCode,
        propertyCity: property?.city,

        // saisis
        enteredMonthlyRent: value.monthlyRent,
        enteredMonthlyProvisionsOnCharges: value.monthlyProvisionsOnCharges,
        surface: value.surface,
        enteredVisitFee: value.visitFee,
        enteredInitialInventoryFee: value.initialInventoryFee,
        enteredBiddingAndNegotiation: value.biddingAndNegotiation,
        enteredAnnualWasteTaxe: value.annualWasteTaxe,
        packFee: value.packFee,
        unpaidRentGuarantee: value.unpaidRentGuarantee,
        enteredAnnualNonOccupantOwnerInsurance:
            value.annualNonOccupantOwnerInsurance,

        // calculé pendant la saisie
        previewTotalRentalFees: value.previewTotalRentalFees,

        // rent totats
        monthlyRentIncludingCharges: rentTotals.monthlyRentIncludingCharges,
        computedVisitFee: rentTotals.visitFee,
        computedInitialInventoryFee: rentTotals.initialInventoryFee,
        computedBiddingAndNegotiation: rentTotals.biddingAndNegotiation,
        totalLocationFee: rentTotals.totalLocationFee,

        // management totats
        computedMonthlyRent: managementTotals.monthlyRent,
        annualRent: managementTotals.annualRent,
        computedMonthlyProvisionsOnCharges:
            managementTotals.monthlyProvisionsOnCharges,
        annualProvisionsOnCharges: managementTotals.annualProvisionsOnCharges,
        computedAnnualWasteTaxe: managementTotals.annualWasteTaxe,
        totalMonthlyGross: managementTotals.totalMonthlyGross,
        totalAnnualGross: managementTotals.totalAnnualGross,
        monthlyManagementFee: managementTotals.monthlyManagementFee,
        annualManagementFee: managementTotals.annualManagementFee,
        monthlyUnpaidRentInsurance: managementTotals.monthlyUnpaidRentInsurance,
        annualUnpaidRentInsurance: managementTotals.annualUnpaidRentInsurance,
        computedMonthlyNonOccupantOwnerInsurance:
            managementTotals.monthlyNonOccupantOwnerInsurance,
        computedAnnualNonOccupantOwnerInsurance:
            managementTotals.annualNonOccupantOwnerInsurance,
        totalMonthlyFees: managementTotals.totalMonthlyFees,
        totalAnnualFees: managementTotals.totalAnnualFees,
        totalMonthlyNet: managementTotals.totalMonthlyNet,
        totalAnnualNet: managementTotals.totalAnnualNet,
    };
}

const sendQuotationButtonProps = {
    color: 'primary',
};

interface QuotationResultProps {
    value: QuotationValuesModel;
    onBack?: () => void;
}

function QuotationResult({ value, onBack }: QuotationResultProps) {
    const [presenter] = usePersistentState(
        CONFIGS.userDataStorageKeys.currentUser
    );
    const rentTotals = useQuotationRentTotals(value);
    const managementTotals = useQuotationManagementTotals(value);
    const [currentPresentation] = useCurrentPresentation();
    const selectedProperty = useProperty(currentPresentation?.propertyId);
    const [selectedAgencyId] = useSelectedAgency();
    const [selectedPackageId] = useSelectedPackageId();
    const valueToSend = useMemo(
        () =>
            makeQuotation(
                value || {},
                rentTotals || {},
                managementTotals || {},
                `${entityToId(presenter)}`,
                selectedProperty,
                selectedAgencyId,
                selectedPackageId
            ),
        [
            value,
            rentTotals,
            managementTotals,
            presenter,
            selectedProperty,
            selectedAgencyId,
            selectedPackageId,
        ]
    );

    return (
        <Stack direction="column" spacing={2}>
            <div>
                <ContentHeading>Votre devis</ContentHeading>
            </div>
            {rentTotals && <QuotationRentResult rentTotals={rentTotals} />}
            {managementTotals && (
                <QuotationManagementResult
                    managementTotals={managementTotals}
                />
            )}
            <QuotationActions sx={{ mt: 2 }}>
                <BigRoundedButton
                    data-testid="backToQuotationFormButton"
                    color="primary"
                    onClick={onBack}
                >
                    Retour
                </BigRoundedButton>
                <SendQuotationFormButton
                    value={valueToSend}
                    buttonComponent={BigRoundedButton}
                    buttonProps={sendQuotationButtonProps}
                >
                    Envoyer le devis
                </SendQuotationFormButton>
            </QuotationActions>
        </Stack>
    );
}

export default QuotationResult;
