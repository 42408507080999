import React from 'react';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'formik-mui';
import { ErrorMessage, Field } from 'formik';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import LineSeparator from '../../../ui/LineSeparator';

interface QuotationInputProps {
    inputId: string;
    label: string;
    InputProps: any;
    type?: React.HTMLInputTypeAttribute | undefined;
    name: string;
}

/**
 * Input aligné avec son label avec une ligne pointillé entre les 2.
 */
function QuotationInput({
    inputId,
    label,
    InputProps,
    type,
    name,
}: QuotationInputProps) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'flex-end',
                }}
            >
                <FormLabel htmlFor={inputId}>{label}</FormLabel>
                <LineSeparator
                    sx={{
                        flex: 1,
                        minWidth: '9px',
                    }}
                />
                <Field
                    component={TextField}
                    type={type}
                    name={name}
                    size="small"
                    sx={{ width: '160px', minWidth: '160px' }}
                    InputProps={{
                        id: inputId,
                        ...InputProps,
                    }}
                />
            </Box>
            <ErrorMessage name={name} component={ErrorHelperText} />
        </>
    );
}

export default QuotationInput;
