import { Box, Grid } from '@mui/material';
import React from 'react';
import ContentHeading from '../content/ContentHeading';
import AdditionalServicesTable from './AdditionalServicesTable';
import InitialServicesTable from './InitialServicesTable';
import PackageSummariesList from './PackageSummariesList';
import PackageTeasersList from './PackageTeasersList';

interface PackagesProps {
    headingId: string;
}

function Packages({ headingId }: PackagesProps) {
    return (
        <>
            <Box
                className="section"
                data-anchor={`${headingId}-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={headingId}
            >
                <ContentHeading size="big">Nos packs de gestion</ContentHeading>
                <Box mt={10}>
                    <PackageTeasersList />
                </Box>
            </Box>
            <Box
                className="section"
                data-anchor={`${headingId}-initiales-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={`${headingId}-initiales`}
            >
                <Grid
                    container
                    direction="column"
                    spacing={0}
                    height="100%"
                    flexWrap="nowrap"
                >
                    <Grid item sm="auto">
                        <ContentHeading size="big" mb={0}>
                            Les prestations initiales
                        </ContentHeading>
                    </Grid>
                    <Grid item sm pt={0} pb={2}>
                        <InitialServicesTable />
                    </Grid>
                </Grid>
            </Box>
            <Box
                className="section"
                data-anchor={`${headingId}-annexes-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={`${headingId}-annexes`}
            >
                <Grid
                    container
                    direction="column"
                    spacing={0}
                    height="100%"
                    flexWrap="nowrap"
                >
                    <Grid item sm="auto">
                        <ContentHeading size="big" mb={0}>
                            Les prestations annexes
                        </ContentHeading>
                    </Grid>
                    <Grid item sm pt={0} pb={2}>
                        <AdditionalServicesTable />
                    </Grid>
                </Grid>
            </Box>
            <Box
                className="section"
                data-anchor={`${headingId}-resume-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={`${headingId}-resume`}
            >
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    height="100%"
                    flexWrap="nowrap"
                >
                    <Grid item sm="auto">
                        <ContentHeading size="big">
                            Nos packs en résumé
                        </ContentHeading>
                    </Grid>
                    <Grid item sm mx={-3}>
                        <PackageSummariesList />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Packages;
