import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import findSettingValueByMultipleSettingCodes, {
    ModuleSettingCodes,
} from '../findSettingValuesByMultipleSettingCodes';
import CONFIGS from '../../../configs';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useAgency from '../../agency/useAgency';
import useAuthenticatedUser from '../../user/useAuthenticatedUser';

function getPublicPath(
    value: ModuleSettingCodes,
    imageOverride: ImageOverrideResponse | undefined,
    user: UserResponse | undefined
): string | null {
    if (
        value[ModuleSettingCode.IMAGE_USER_PHOTO]?.value === 'true' &&
        user?.photo?.publicPath
    ) {
        return user?.photo?.publicPath;
    }
    if (imageOverride) {
        return imageOverride.imageOverrideFile?.publicPath || null;
    }
    return value[ModuleSettingCode.IMAGE]?.file?.publicPath || null;
}

export interface ImageModuleProps {
    contentModule: ContentModuleResponse;
    exportPdf?: boolean;
}

function ImageModule({ contentModule, exportPdf }: ImageModuleProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const value = useMemo(
        () =>
            findSettingValueByMultipleSettingCodes(
                [
                    ModuleSettingCode.IMAGE,
                    ModuleSettingCode.IMAGE_FIT,
                    ModuleSettingCode.IMAGE_HORIZONTAL_POSITION,
                    ModuleSettingCode.IMAGE_VERTICAL_POSITION,
                    ModuleSettingCode.ALT_NAME,
                    ModuleSettingCode.IMAGE_USER_PHOTO,
                ],
                contentModule
            ),
        [contentModule]
    );
    const imageOverride = useMemo(() => {
        if (!agency) {
            return undefined;
        }

        return agency.imageOverrides?.collection.find((io) =>
            matchOnId(io.contentModule, contentModule)
        );
    }, [agency, contentModule]);
    const user = useAuthenticatedUser();

    const publicPath = getPublicPath(value, imageOverride, user);

    return (
        <Box height="100%">
            {publicPath && (
                <Box
                    component="img"
                    alt={value[ModuleSettingCode.ALT_NAME]?.value || ''}
                    sx={{
                        height: '100%',
                        width: '100%',
                        objectFit:
                            value[ModuleSettingCode.IMAGE_FIT]?.value || 'none',
                        objectPosition: `${
                            value[ModuleSettingCode.IMAGE_HORIZONTAL_POSITION]
                                ?.value || 'center'
                        } ${
                            value[ModuleSettingCode.IMAGE_VERTICAL_POSITION]
                                ?.value || 'center'
                        }`,
                    }}
                    src={`${
                        exportPdf
                            ? CONFIGS.dockerApiEntrypoint
                            : CONFIGS.apiEntrypoint
                    }${publicPath}`}
                />
            )}
        </Box>
    );
}

export default ImageModule;
