import PresentationFlowCode from '@acdc/shared/src/features/presentationFlow/PresentationFlowCode.enum';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';

function getPresentationFlowColor(
    presentationFlowIri: string | { id?: string } | undefined
) {
    const presentationFlowCode = getIdFromIri(entityToId(presentationFlowIri));

    switch (presentationFlowCode) {
        case PresentationFlowCode.LOCATION:
            return ThemeColor.RENTAL;
        case PresentationFlowCode.SYNDIC:
            return ThemeColor.SYNDICATE;
        default:
            return ThemeColor.SALE;
    }
}

export default getPresentationFlowColor;
