import React, { useEffect } from 'react';
import { Field, FieldAttributes, useFormikContext, ErrorMessage } from 'formik';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import { TextField } from 'formik-mui';
import { Box, InputAdornment } from '@mui/material';
import QuotationFormValue from './QuotationFormValue';

/**
 * Champ contenant la prévisualisation de l'honoraire de location dans le formulaire QuotationForm.
 * La valeur est calculée automatiquement à partir des champs avec les names "subscribedLandDeclaration", "initialInventoryFee" et "biddingAndNegotiation".
 */
function PreviewTotalRentalFees({ name, ...props }: FieldAttributes<any>) {
    const {
        values: {
            surface,
            visitFee,
            initialInventoryFee,
            biddingAndNegotiation,
        },
        setFieldValue,
    } = useFormikContext<QuotationFormValue>();

    useEffect(() => {
        if (surface !== '' && visitFee !== '' && initialInventoryFee !== '') {
            const landDeclaration: number = +surface * +visitFee;
            const inventoryFee: number = +surface * +initialInventoryFee;

            const result: number =
                landDeclaration + inventoryFee + +biddingAndNegotiation;

            setFieldValue(name, result);
        } else {
            setFieldValue(name, '');
        }
    }, [
        surface,
        visitFee,
        initialInventoryFee,
        biddingAndNegotiation,
        setFieldValue,
        name,
    ]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: 'solid thin',
                borderRadius: 1,
                px: 2,
                pt: 2,
                margin: 'auto !important',
            }}
        >
            Honoraires prévisionnels{' '}
            <Field
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                    ),
                    inputProps: {
                        'data-testid': 'previewTotalRentalFeesInput',
                    },
                    size: 'small',
                    style: {
                        maxWidth: '150px',
                        fontWeight: 'bold',
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    alignItems: 'flex-end',
                }}
                component={TextField}
                type="number"
                margin="none"
                name={name}
                {...props}
            />
            <ErrorMessage name={name} component={ErrorHelperText} />
        </Box>
    );
}

export default PreviewTotalRentalFees;
