/* eslint-disable no-prototype-builtins */
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import moduleSettingsCodePartner from '@acdc/shared/src/features/partner/moduleSettingsCodePartner';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import findSettingValueByMultipleSettingCodes from '../findSettingValuesByMultipleSettingCodes';
import CONFIGS from '../../../configs';
import findImageOverrideByMultipleSettingCodes from '../findImageOverrideByMultipleSettingCodes';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useAgency from '../../agency/useAgency';
import hasSharedFileOrImageOverrideFile from '../sharedFiles/hasSharedFileOrImageOverrideFile';

const partnerSettingCodes = moduleSettingsCodePartner();

export interface PartnersModuleProps {
    contentModule: ContentModuleResponse;
    exportPdf?: boolean;
}

const listPartnersSx = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 2fr)',
    gridTemplateRows: ' repeat(4, 25%)',
    maxHeight: '100%',
    paddingTop: 4,
    paddingBottom: 4,
};

const picturePartnersSx = {
    height: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
};

const containerImageSx = {
    gridColumn: 'span 2',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',

    '&:last-of-type:nth-of-type(odd)': {
        gridColumnStart: '2',
        gridColumnEnd: '4',
    },
};

function PartnersModule({ contentModule, exportPdf }: PartnersModuleProps) {
    const selectedAgency = useAgency(useSelectedAgency()[0]);

    const values = useMemo(() => {
        if (partnerSettingCodes) {
            return findSettingValueByMultipleSettingCodes(
                partnerSettingCodes,
                contentModule
            );
        }
        return undefined;
    }, [contentModule]);

    const imageOverrides = useMemo(
        () =>
            findImageOverrideByMultipleSettingCodes(
                partnerSettingCodes,
                selectedAgency?.imageOverrides?.collection.filter((i) =>
                    matchOnId(i.contentModule, contentModule)
                )
            ),
        [selectedAgency?.imageOverrides?.collection, contentModule]
    );

    const hasOverrideFile = useMemo(
        () =>
            hasSharedFileOrImageOverrideFile(
                imageOverrides,
                partnerSettingCodes
            ),
        [imageOverrides]
    );

    const items = useMemo(() => {
        const paths: {
            key: ModuleSettingCode;
            publicPath: string;
        }[] = [];

        if (imageOverrides) {
            partnerSettingCodes.forEach((settingCode) => {
                let publicPath = null;
                if (hasOverrideFile) {
                    publicPath = imageOverrides[settingCode]?.sharedFile
                        ? imageOverrides[settingCode]?.sharedFile?.publicPath
                        : imageOverrides[settingCode]?.imageOverrideFile
                              ?.publicPath;
                } else if (values) {
                    publicPath = values[settingCode]?.sharedFile
                        ? values[settingCode]?.sharedFile?.publicPath
                        : values[settingCode]?.file?.publicPath;
                }

                if (publicPath) {
                    paths.push({ key: settingCode, publicPath });
                }
            });
        }

        return paths;
    }, [imageOverrides, hasOverrideFile, values]);

    return (
        <Box height="100%" padding={1} sx={listPartnersSx}>
            {items?.map((item) => (
                <Box sx={containerImageSx} key={item.key}>
                    <Box
                        component="img"
                        src={`${
                            exportPdf
                                ? CONFIGS.dockerApiEntrypoint
                                : CONFIGS.apiEntrypoint
                        }${item.publicPath}`}
                        sx={picturePartnersSx}
                    />
                </Box>
            ))}
        </Box>
    );
}

export default PartnersModule;
