import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import usePresentationFlows from '../features/presentationFlow/usePresentationFlows';
import ToolCardSkeleton from '../ui/ToolCardSkeleton';
import ToolCard from '../ui/ToolCard';
import StoreIcon from '../ui/icons/StoreIcon';
import useStartPresentation from '../features/presentation/useStartPresentation';
import useProperties from '../features/property/useProperties';
import PropertyListItem from '../features/property/PropertyListItem';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import getPresentationFlowColor from '../features/presentationFlow/getPresentationFlowColor';

const itemSize: number = 3; // sur 12

function RendezVous() {
    const presentationFlows = usePresentationFlows();
    const properties = useProperties();
    const [selectedAgency] = useSelectedAgency();

    const agencyProperties = useMemo<PropertyResponse[] | undefined>(() => {
        if (!properties) {
            return undefined;
        }

        return properties?.filter((property) =>
            matchOnId(property.agency, selectedAgency)
        );
    }, [properties, selectedAgency]);

    const startPresentation = useStartPresentation();

    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Mes rendez-vous
            </Typography>
            <Grid container spacing={4} sx={{ marginTop: 2 }}>
                {Boolean(presentationFlows) &&
                    agencyProperties?.map((property) => (
                        <Grid
                            key={property.id}
                            item
                            xs={itemSize}
                            data-testid={`property${property.id}`}
                        >
                            <PropertyListItem
                                property={property}
                                color={getPresentationFlowColor(
                                    property.presentationFlow
                                )}
                            />
                        </Grid>
                    ))}
                {presentationFlows?.map((presentationFlow) => (
                    <Grid
                        key={presentationFlow.id}
                        item
                        xs={itemSize}
                        data-testid={`presentationFlow${presentationFlow.id}`}
                    >
                        <ToolCard
                            color={getPresentationFlowColor(presentationFlow)}
                            icon={StoreIcon}
                            buttonText={presentationFlow.label || ''}
                            onClick={() => startPresentation(presentationFlow)}
                        />
                    </Grid>
                ))}
                {!presentationFlows &&
                    [1, 2].map((key) => (
                        <Grid key={key} item xs={itemSize}>
                            <ToolCardSkeleton />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default RendezVous;
