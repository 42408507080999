import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { useMemo } from 'react';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import usePackageAgencyOfPackage from '../packageAgency/usePackageAgencyOfPackage';
import CONFIGS from '../../configs';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';

/**
 * @see api/src/Service/PdfGenerator/QuotationPdfGenerator.php
 */
function getLabel(
    pack: PackageResponse,
    agency: AgencyResponse,
    packageAgency: PackageAgencyResponse | undefined
) {
    if (packageAgency?.labelOverride) {
        return packageAgency.labelOverride;
    }

    if (agency.useNewPackages) {
        return pack.newLabel;
    }

    return pack.label;
}

function getColor(pack: PackageResponse, agency: AgencyResponse) {
    if (agency.useNewPackages) {
        return pack.newColor;
    }

    return pack.color;
}

function getPackageImageUrlPublicPath(
    pack: PackageResponse,
    agency: AgencyResponse
) {
    if (agency.useNewPackages) {
        return pack.newPackageImage?.publicPath;
    }

    return pack.packageImage?.publicPath;
}

export function getOverridedPackage(
    pack: PackageResponse,
    agency: AgencyResponse,
    packageAgency: PackageAgencyResponse | undefined
) {
    return {
        id: pack.id,
        label: getLabel(pack, agency, packageAgency),
        color: getColor(pack, agency),
        packageImageUrl: `${
            CONFIGS.apiEntrypoint
        }${getPackageImageUrlPublicPath(pack, agency)}`,
    };
}

export interface OverridedPackage {
    id: string | undefined;
    label: string | undefined;
    color: string | undefined;
    packageImageUrl: string;
}

/**
 * Retourne un OverridedPackage avec les propriétés définies en fonction des configuration de
 * l'agence selectionnée.
 */
function useOverridedPackage(
    pack: PackageResponse | undefined
): OverridedPackage | null {
    const agency = useAgency(useSelectedAgency()[0]);
    const packageAgency = usePackageAgencyOfPackage(agency, pack);

    return useMemo(() => {
        if (!pack || !agency) {
            return null;
        }

        return getOverridedPackage(pack, agency, packageAgency);
    }, [pack, agency, packageAgency]);
}

export default useOverridedPackage;
